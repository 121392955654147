import { type ChangeEvent } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Box, Checkbox, FormControlLabel, Link, Stack, Typography } from "@mui/material";

type Props = {
  roleCreationConfirmed: boolean;
  onRoleCreationConfirmChange: (boolean) => void;
};

export default function RolesAndPermissionsStep({ roleCreationConfirmed, onRoleCreationConfirmChange }: Props) {
  return (
    <Stack spacing={4} data-cy="roles-and-permissions-step" mb={4}>
      <Typography variant="h3">Create a custom role for DoiT and assign permissions</Typography>

      <Alert severity="info">
        If you belong to a multi-level organization, please log in using your parent organization.
      </Alert>

      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          Required permissions to connect your Datadog account
        </Typography>
        <Typography fontFamily="monospace" pl={1} mt={1} color="text.secondary">
          • Administrator
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          Create a role and assign permissions
        </Typography>
        <Stack spacing={2} mt={1}>
          <Box display="flex">
            <Typography variant="body1">1. Navigate to the</Typography>
            &nbsp;
            <Link
              sx={{
                alignItems: "center",
                display: "flex",
                gap: 0.5,
              }}
              color="primary"
              variant="body1"
              underline="none"
              target="_blank"
              href="https://app.datadoghq.com/account/login"
            >
              Datadog Platform
              <OpenInNewIcon fontSize="inherit" />
            </Link>
          </Box>
          <Typography variant="body1">2. Go to your user settings</Typography>
          <Typography variant="body1">3. Select ROLES</Typography>
          <Typography variant="body1">4. Enable creating CUSTOM ROLES</Typography>
          <Typography variant="body1">5. Select NEW ROLE and name it "DoiT Connector Role"</Typography>
          <Typography variant="body1">6. Add the following permissions: BILLING READ and USAGE READ</Typography>
          <Typography variant="body1">7. Allow team reads permissions (optional)</Typography>
          <Typography variant="body1">8. Enable tag reports (optional)</Typography>
        </Stack>
      </Box>

      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          Confirm creation of the role
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={roleCreationConfirmed}
              name="confirmRoleCreation"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                onRoleCreationConfirmChange(event.target.checked);
              }}
            />
          }
          label="I have created a custom role and assigned permissions"
        />
      </Box>
    </Stack>
  );
}
