import { type Dispatch, type SetStateAction, useCallback, useEffect } from "react";

import { Alert, Box, Button, Stack, Typography } from "@mui/material";

import { type ProductOption, type Subscription as SubscriptionType } from "../../types";
import Subscription from "./Subscription";

type Props = {
  subscriptions: SubscriptionType[];
  onChange: Dispatch<SetStateAction<SubscriptionType[]>>;
  productOptions: ProductOption[];
};

export default function SubscriptionStep({ subscriptions, onChange, productOptions }: Props) {
  const handleAddSubscription = useCallback(
    (firstSubscription?: boolean) => {
      const products = productOptions.slice();

      const subscription: SubscriptionType = {
        errors: {},
        plan: "",
        startDate: null,
        endDate: null,
        products: firstSubscription
          ? products.map((product) => ({ ...product, quantity: null, price: null, errors: {} }))
          : [{ label: "", id: "", usageType: "", quantity: null, price: null, errors: {} }],
      };
      onChange((prevItems) => [...prevItems, subscription]);
    },
    [productOptions, onChange]
  );

  useEffect(() => {
    if (subscriptions.length === 0) {
      handleAddSubscription(true);
    }
  }, [subscriptions.length, handleAddSubscription]);

  const handleUpdateSubscription = useCallback(
    (index: number, data: SubscriptionType) => {
      onChange((prevItems) => prevItems.map((item, i) => (i === index ? data : item)));
    },
    [onChange]
  );

  const handleRemoveSubscription = useCallback(
    (index: number) => {
      onChange((prevItems) => prevItems.filter((_, i) => i !== index));
    },
    [onChange]
  );

  return (
    <Stack spacing={3}>
      <Typography variant="h3" sx={{ fontWeight: 500 }}>
        Subscription details
      </Typography>

      <Alert severity="info">
        We pre-filled your subscriptions and products, please make sure the information is correct and the right
        quantity and PPU.
      </Alert>

      {subscriptions.map((subscription, index) => (
        <Subscription
          key={index}
          subscription={subscription}
          productOptions={productOptions}
          onChange={handleUpdateSubscription}
          onRemove={handleRemoveSubscription}
          index={index}
        />
      ))}

      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            handleAddSubscription();
          }}
        >
          Add new subscription details
        </Button>
      </Box>
    </Stack>
  );
}
