import { type ChangeEvent, useCallback } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Box, Grid2 as Grid, IconButton, Paper, Stack, TextField, Tooltip } from "@mui/material";

import { type Product, type ProductOption } from "../../types";

type Props = {
  productIndex: number;
  product: Product;
  productOptions: ProductOption[];
  onChange: (productIndex: number, updatedProduct: Product) => void;
  onDelete: (productIndex: number) => void;
  deleteDisabled: boolean;
};

export default function Product({ product, productOptions, onChange, productIndex, onDelete, deleteDisabled }: Props) {
  const handleChangeNumber = useCallback(
    (name: "quantity" | "price") => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value: number | null = null;

      if (event.target.value !== "") {
        value = Number(event.target.value);

        if (isNaN(value) || value < 0 || !isFinite(value)) {
          value = null;
        }
      }

      const updatedProduct = { ...product, [name]: value, errors: {} };

      onChange(productIndex, updatedProduct);
    },
    [product, onChange, productIndex]
  );

  const handleChangeType = useCallback(
    (_, selectedProduct: ProductOption) => {
      const updatedProduct: Product = {
        ...selectedProduct,
        price: null,
        quantity: null,
        errors: {},
      };
      onChange(productIndex, updatedProduct);
    },
    [onChange, productIndex]
  );

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Grid container spacing={1} flexGrow={1}>
          <Grid size={6}>
            <Autocomplete
              id="product-type"
              data-cy="product-type"
              blurOnSelect
              disableClearable
              options={productOptions}
              value={productOptions.find((option) => option.label === product.label)}
              onChange={handleChangeType}
              isOptionEqualToValue={(o, v) => o.label === v.label}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product type"
                  fullWidth
                  error={!!product.errors.label}
                  helperText={product.errors.label}
                />
              )}
            />
          </Grid>
          <Grid size={3}>
            <TextField
              label="Quantity"
              value={product.quantity}
              type="number"
              required
              onChange={handleChangeNumber("quantity")}
              error={!!product.errors.quantity}
              helperText={product.errors.quantity}
            />
          </Grid>
          <Grid size={3}>
            <TextField
              label="Price per unit"
              value={product.price}
              type="number"
              required
              onChange={handleChangeNumber("price")}
              error={!!product.errors.price}
              helperText={product.errors.price}
            />
          </Grid>
        </Grid>
        <Box>
          <Tooltip title={deleteDisabled ? "Subscription should have at least one product" : ""}>
            <Box sx={{ cursor: "pointer" }}>
              <IconButton
                data-cy="closeButton"
                aria-label="close"
                disabled={deleteDisabled}
                onClick={() => {
                  onDelete(productIndex);
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Stack>
    </Paper>
  );
}
