import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

export type SnowflakeError = {
  message: string | null;
  ts: Timestamp;
};

export enum OnboardingIssueType {
  TableNotFound = "Table Not Found",
  PermissionNotFound = "Permission Not Found",
}
export type OnboardingIssue = {
  Type: OnboardingIssueType;
  Detail: string;
};

@subCollection("snowflake-configuration")
export class DataConnectorSnowflakeModel {
  accountId!: string;

  customerId!: string;

  error!: SnowflakeError;

  lastUpdated!: Timestamp;

  orgId!: string;

  status!: string;

  timeCreated!: Timestamp;

  user!: string;

  OnboardingIssues?: OnboardingIssue[];
}

export type DatadogError = {
  message: string | null;
  ts: Timestamp;
};

export type DatadogProduct = {
  id: string;
  label: string;
  price: number;
  quantity: number;
  usageType: string;
};

export type DataDogSubscription = {
  endDate: Timestamp;
  plan: string;
  products: DatadogProduct[];
  startDate: Timestamp;
};

@subCollection("datadog-configuration")
export class DataConnectorDatadogModel {
  customerId!: string;

  error!: DatadogError;

  lastUpdated!: Timestamp;

  onboardedBy!: string;

  orgId!: string;

  status!: string;

  site!: string;

  subscriptions!: DataDogSubscription[];

  timeCreated!: Timestamp;
}

@collection("dataConnectors")
export class DataConnectorModel {
  subCollections!: {
    "snowflake-configuration": DataConnectorSnowflakeModel;
    "datadog-configuration": DataConnectorDatadogModel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}
