import cloneDeep from "lodash/cloneDeep";

import { type ProductOption, type Subscription as SubscriptionType } from "../types";

type Props = {
  subscriptions: SubscriptionType[];
  productOptions: ProductOption[];
};

export const dateFields = ["startDate", "endDate"];
export const subscriptionRequiredFields = ["plan", "startDate", "endDate"];
export const productRequiredFields = ["label", "price", "quantity"];

export const validateSubscriptions = ({ subscriptions, productOptions }: Props) => {
  let isError = false;
  let errorMessage = "";
  const isCustomerWithoutCommittedProducts = !productOptions.length;

  if (isCustomerWithoutCommittedProducts) {
    return { validatedSubscriptions: [], isError, errorMessage };
  }

  const validatedSubscriptions = cloneDeep(subscriptions);

  const productOptionsMap = {};

  productOptions.forEach(({ label }) => {
    productOptionsMap[label] = 0;
  });

  validatedSubscriptions.forEach((subscription) => {
    subscriptionRequiredFields.forEach((field) => {
      subscription.errors[field] = !subscription[field] ? "Must be filled" : "";
      if (subscription.errors[field]) {
        isError = true;
      }
    });

    dateFields.forEach((field) => {
      if (subscription[field] && !subscription[field]?.isValid) {
        isError = true;
        subscription.errors[field] = "Invalid input";
      }
    });

    if (subscription.startDate && subscription.endDate && subscription.endDate < subscription.startDate) {
      isError = true;
      subscription.errors.endDate = "End date should be after start date";
    }

    subscription.products.forEach((product) => {
      productRequiredFields.forEach((field) => {
        if (field === "label") {
          product.errors[field] = product[field] ? "" : "Must be filled";
        } else {
          product.errors[field] = product[field] ? "" : "Must be greater than zero";
        }

        if (product.errors[field]) {
          isError = true;
        }
      });

      if (product.label) {
        if (productOptionsMap[product.label]) {
          product.errors.label = "Each product can be only selected once";
          isError = true;
        } else {
          productOptionsMap[product.label]++;
        }
      }
    });
  });

  const nonUsedProducts = Object.entries(productOptionsMap)
    .filter(([_, num]) => num === 0)
    .map(([label]) => label);

  if (nonUsedProducts.length) {
    isError = true;
    errorMessage = `You must select all the remaining products: ${nonUsedProducts.join(", ")}.`;
  }

  return { validatedSubscriptions, isError, errorMessage };
};
