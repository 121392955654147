import { type Product, type Subscription } from "../types";

type FormattedProduct = Omit<Product, "errors">;

type FormattedSubscription = Omit<Subscription, "errors" | "products"> & { products: FormattedProduct[] };

export const formatSubscription = (subscription: Subscription): FormattedSubscription => ({
  plan: subscription.plan,
  startDate: subscription.startDate,
  endDate: subscription.endDate,
  products: subscription.products.map(
    (product) =>
      ({
        id: product.id,
        label: product.label,
        usageType: product.usageType,
        quantity: product.quantity,
        price: product.price,
      }) satisfies FormattedProduct
  ),
});
